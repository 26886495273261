const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)

if (urlParams.has("hfa")) {
    urlParams.delete("hfa")
    let params = urlParams.toString()
    let pathWithoutHfa = location.pathname
    if (params) {
        pathWithoutHfa = `${pathWithoutHfa}?${params}`
    }
    history.pushState({}, null, pathWithoutHfa)
}
